import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import slide1 from '../../static/assets/slider/mobile/slide-1.png';
import slide2 from '../../static/assets/slider/desktop/slide-2.png';
import slide3 from '../../static/assets/slider/mobile/slide-3.png';
import slide4 from '../../static/assets/slider/desktop/slide-4.png';
import slide5 from '../../static/assets/slider/mobile/slide-5.png';
import slide6 from '../../static/assets/slider/desktop/slide-6.png';
import slide7 from '../../static/assets/slider/mobile/slide-7.png';
import slide8 from '../../static/assets/slider/desktop/slide-8.png';
import slide9 from '../../static/assets/slider/desktop/slide-9.png';

const IndexPage = () => (
  <Layout>
    <SEO title="EasyBusy: Make achieving easier!" />

    <div className={'page-header home'}>
      <h1>Make achieving easier!</h1>
      <p>
        EasyBusy is a productivity app that will help you keep all your tasks and
        ideas in one place, complete them on time and never forget anything, all that
        will fun and no stress!
      </p>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Use app on any platform</h2>
          <p>
            Install our app on any device or computer and sync all your data with
            your other mobile devices or across multiple computers.
          </p>

          <div className={'buttons-list'}>
            <div className={'button'}>
              <a href="/versions/mobile">
                <i className="fas fa-mobile-alt" />
                Mobile app
              </a>
            </div>

            <div className={'button'}>
              <a href="/versions/desktop">
                <i className="fas fa-desktop" />
                Desktop app
              </a>
            </div>

            <div className={'button'}>
              <a href="/versions/web">
                <i className="fab fa-safari" />
                Web version
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'page-header home'}>
      <img alt={'slide1'} src={slide1} />
      <img alt={'slide2'} src={slide2} />
      <img alt={'slide3'} src={slide3} />
      <img alt={'slide4'} src={slide4} />
      <img alt={'slide5'} src={slide5} />
      <img alt={'slide6'} src={slide6} />
      <img alt={'slide7'} src={slide7} />
      <img alt={'slide9'} src={slide9} />
      <img alt={'slide8'} src={slide8} />
    </div>
  </Layout>
);

export default IndexPage;
